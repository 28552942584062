* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

.container {
    margin: 10px;
    margin: auto;
}

.nav {
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
}

.site-title {
    font-size: 2rem;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.nav li:hover {
    background-color: #777;
}

.nav li.active {
    background-color: #555;
}

.auth-user-information {
    border: 2px black solid;
}

.functional-block {
    border: #2b2f32 solid 2px;
    padding: 10px;
    margin-top: 5px;
    border-radius: 10px;
}

.status-update {
    font-size: 10px;
}


